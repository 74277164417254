import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import StickyHeadTable from './table';
import "../dashboard/table.css"
import StickyHeadTable2 from './all-clinics';
import { useNavigate } from 'react-router-dom';
import { Email, EmailOutlined, LogoutOutlined, Person, Person2, VerifiedUserSharp } from '@mui/icons-material';
import logo from '../assets/logo.png';
import { browserSessionPersistence, signOut } from 'firebase/auth';
import { auth } from '../firebase';


const drawerWidth = 240;

function ResponsiveDrawer2(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  const navigateTDashboard = () => {
    navigate('/dashboard'); // Change the path as per your route configuration
  };
  const handleLogout = async () => {
    try {
        await signOut(auth);
        // Remove session persistence
        auth.setPersistence(browserSessionPersistence)
            .then(() => {
                console.log("Session persistence removed successfully!");
            })
            .catch((error) => {
                console.error("Error removing session persistence:", error);
            });
        navigate("/");
    } catch (error) {
        console.error('Error signing out:', error);
    }
};
const navigateToEmailSubmissions = () => {
  navigate('/email-submissions');
};
const handleUsers = () =>{
  navigate('/users');
};

  const drawer = (
    <div>
     
      <Toolbar  />
    
      <Divider />
      <List style={{marginTop:"0"}}>
  {['Awaiting Approvals', 'Providers'].map((text, index) => (
    <ListItem key={text} disablePadding>
      <ListItemButton onClick={text === 'Awaiting Approvals' ? navigateTDashboard : null}>
        <ListItemIcon>
          {index === 0 ? <InboxIcon /> : index === 1 ? <MailIcon /> : <VerifiedUserSharp />}
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  ))}
</List>


      <Divider />
      <ListItem key="Email Submissions" disablePadding>
  <ListItemButton onClick={navigateToEmailSubmissions}>
    <ListItemIcon>
      <VerifiedUserSharp />
    </ListItemIcon>
    <ListItemText primary="Email Submissions" />
  </ListItemButton>
</ListItem>
<ListItem key="Logout" disablePadding>
          <ListItemButton onClick={handleUsers}>
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItemButton>
        </ListItem>

      <List>
        <ListItem key="Logout" disablePadding>
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <LogoutOutlined />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor:"transparent",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="black"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" >
          <img src={logo} alt="Logo" width={"200px"}/>

          </Typography>
          <Typography variant="h6" noWrap component="div" className='all-clinics-main'>
           <h2 className='appbar-h'>
            {/* All Clinics */}
           </h2>
          </Typography>
         
         
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, 
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
       <StickyHeadTable2/>
      </Box>
    </Box>
  );
}

ResponsiveDrawer2.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer2;