import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { firestore } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'email', label: 'Email', minWidth: 170, align: 'center' },
    { id: 'message', label: 'Message', minWidth: 170, align: 'center' },
    { id: 'time', label: 'Time', minWidth: 170, align: 'center' },
    { id: 'contactno', label: 'Contact No', minWidth: 170, align: 'center' },
];

export default function SubmitedEmails() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(firestore, 'messages'));
                const fetchedData = [];

                querySnapshot.forEach((doc) => {
                    const clinicData = doc.data();
                    fetchedData.push({
                        id: doc.id, 
                        name: clinicData.name,
                        email: clinicData.email,
                        message: clinicData.message,
                        time: formatDate(clinicData.time.toDate()), 
                        contactno: clinicData.phoneNumber,
                    });
                });

                setData(fetchedData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Function to format date
    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '30px' }}>
            {loading ? (
                <div className="loader">
                    <div className="loader__balls">
                        <div className="loader__balls__group">
                            <div className="ball item1"></div>
                            <div className="ball item1"></div>
                            <div className="ball item1"></div>
                        </div>
                        <div className="loader__balls__group">
                            <div className="ball item2"></div>
                            <div className="ball item2"></div>
                            <div className="ball item2"></div>
                        </div>
                        <div className="loader__balls__group">
                            <div className="ball item3"></div>
                            <div className="ball item3"></div>
                            <div className="ball item3"></div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <TableContainer sx={{ maxHeight: "55vh" }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, fontWeight: 'bolder', fontSize: "18px" }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align} style={{ fontWeight: "bold" }}>
                                                        {column.id === 'website' ? (
                                                            <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>
                                                        ) : (
                                                            value
                                                        )}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}
        </Paper>
    );
}
