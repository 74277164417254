import { useState } from "react";
import SignUpForm from "./signup";
import SignInForm from "./login";


export default function MainLogin(){
    const [type, setType] = useState("signUp");

  
    const containerClass =
      "container " + (type === "signUp" ? "right-panel-active" : "");
    return(
        <>
         <div className="App">
     
      <div className={containerClass} id="container">
        <SignUpForm />
       
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-left">
            <img src="admin-logo.png" alt="" />
              <p>Wake up closer to the smile that you have always dreamed of.</p>
             
            </div>
            <div className="overlay-panel overlay-right">
              <img src="admin-logo.png" alt="" />
              <p>Wake up closer to the smile that you have always dreamed of.</p>
           
            </div>
          </div>
        </div>
      </div>
    </div>
        </>
    )
}