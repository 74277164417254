import { useState } from "react";
import SignUpForm from "./signup";
import SignInForm from "./login";


export default function MainLogin(){
    const [type, setType] = useState("signIn");
    const handleOnClick = text => {
      if (text !== type) {
        setType(text);
        return;
      }
    };
    const containerClass =
      "container " + (type === "signUp" ? "right-panel-active" : "");
    return(
        <>
         <div className="App">
     
      <div className={containerClass} id="container">
        <SignUpForm />
        <SignInForm />
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-left">
            <img src="admin-logo.png" alt="" />
              <p>Wake up closer to the smile that you have always dreamed of.</p>
              <button
                className="ghost"
                id="signIn"
                onClick={() => handleOnClick("signIn")}
              >
                Sign In
              </button>
            </div>
            <div className="overlay-panel overlay-right">
              <img src="admin-logo.png" alt="" />
              <p>Wake up closer to the smile that you have always dreamed of.</p>
           
            </div>
          </div>
        </div>
      </div>
    </div>
        </>
    )
}