import React, { useState } from "react";
import "../login/login.css";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useHistory, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getFirestore, collection, addDoc, Timestamp } from "firebase/firestore";

function SignUpForm() {
  const [state, setState] = useState({
    email: "",
    name: "",
    password: "",
    confirmPassword: ""
  });

  const auth = getAuth();
  const firestore = getFirestore();
  const navigate = useNavigate();

  const handleChange = evt => {
    const { name, value } = evt.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const { email, name, password, confirmPassword } = state;

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Save user data to Firestore with UID as document ID
      await addDoc(collection(firestore, "users"), {
        uid: user.uid,
        email: email,
        name: name,
        registeredAt: Timestamp.fromDate(new Date())
      });

      navigate("/");
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
      toast.error(errorMessage);
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="form-container sign-up-container">
        <form onSubmit={onSubmit}>
          <h1>Add User</h1>
          <br />
          <input
            type="text"
            name="name"
            value={state.name}
            onChange={handleChange}
            placeholder="User Name"
            required
          />
          <input
            type="email"
            name="email"
            value={state.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
          <input
            type="password"
            name="password"
            value={state.password}
            onChange={handleChange}
            placeholder="Password"
            required
          />
          <input
            type="password"
            name="confirmPassword"
            value={state.confirmPassword}
            onChange={handleChange}
            placeholder="Confirm Password"
            required
          />
          <button type="submit">Add User</button>
        </form>
      </div>
    </>
  );
}

export default SignUpForm;
