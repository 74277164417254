import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button'; 
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import { auth, firestore } from '../firebase';
import '../dashboard/table.css';
import { collection, deleteDoc, doc, getDocs, query, addDoc, where } from 'firebase/firestore';
import { Delete, Done, Edit, EditOutlined } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { deleteUser } from 'firebase/auth';

const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'email', label: 'Email', minWidth: 100, align:'center' },
    { id: 'date', label: 'Date', minWidth: 170, align: 'center' },
    { id: 'action', label: 'Action', minWidth: 170, align: 'center' },
];

export default function Users() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); 
  const [deleteDocId, setDeleteDocId] = useState(null); 
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(query(collection(firestore, 'users')));
        const fetchedData = [];

        querySnapshot.forEach((doc) => {
          const clinicData = doc.data();
          // Formatting the date
          const date = new Date(clinicData.registeredAt.seconds * 1000); // Convert Firestore timestamp to JavaScript Date object
          const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
          fetchedData.push({
            name: clinicData.name,
            email: clinicData.email,
            date: formattedDate,
            uid:clinicData.uid,
          });
        });

        setData(fetchedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = data.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  const AddUser = () =>{
      navigate('/add-user');
  };

  const handleDeleteButtonClick = (uid) => {
    setDeleteDocId(uid); // Set the UID of the user to be deleted
    setDeleteDialogOpen(true); // Open the dialog for confirmation
  };
  
  // Function to delete user from Firebase Authentication and Firestore
  const deleteUserFromAuthentication = async (uid) => {
    try {
      await deleteUser(auth.currentUser); // Use the deleteUser function from Firebase Authentication
      console.log('User deleted successfully from Authentication');
    } catch (error) {
      console.error('Error deleting user from Authentication:', error);
      throw error; // Throw the error to handle it in the calling function
    }
  };
  
  const handleDeleteConfirmed = async () => {
    try {
      // Delete user from Firebase Authentication
      await deleteUserFromAuthentication(deleteDocId);
  
      const userQuery = query(collection(firestore, 'users'), where('uid', '==', deleteDocId));
      const querySnapshot = await getDocs(userQuery);
      
      if (!querySnapshot.empty) {
        // Delete the document
        querySnapshot.forEach(async (doc) => {
          await deleteDoc(doc.ref);
        });
      }
  
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };
  

  return (
    <>
     {loading ? ( // Render custom loader if data is loading
        <div className="loader">
        <div className="loader__balls">
          <div className="loader__balls__group">
            <div className="ball item1"></div>
            <div className="ball item1"></div>
            <div className="ball item1"></div>
          </div>
          <div className="loader__balls__group">
            <div className="ball item2"></div>
            <div className="ball item2"></div>
            <div className="ball item2"></div>
          </div>
          <div className="loader__balls__group">
            <div className="ball item3"></div>
            <div className="ball item3"></div>
            <div className="ball item3"></div>
          </div>
        </div>
      </div>
      
      ) : (
      <Paper sx={{ width: '100%', overflow: 'hidden', marginTop:"30px" }}>
     <div className="user-search">
  <button onClick={AddUser}>
    Add User
  </button>
     <input
                className='search-bar'
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearch}
                style={{  marginRight: '20px', marginTop: '10px', width: '40%' }}
            />
     </div>
        <TableContainer sx={{ maxHeight: "55vh" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: 'bolder',fontSize:"18px" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} style={{fontWeight:"bold"}}>
                          {column.id === 'website' ? (
                            <a href={value} target="_blank">{value}</a>
                          ) : column.id === 'action' ? (
                            <div>
                             
                             <IconButton style={{ backgroundColor:"red", marginLeft:"10px" }} onClick={() => handleDeleteButtonClick(row.uid)}>
  <Delete style={{ color:"white" }} />
</IconButton>
                            </div>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
       <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
  <DialogTitle style={{paddingTop:"20px"}}>Confirm Delete</DialogTitle>
  <DialogContent>
    <DialogContentText style={{paddingLeft:"40px",paddingRight:"40px"}}>Are you sure you want to delete this item?</DialogContentText>
  </DialogContent>
  <DialogActions style={{padding:"20px",display:"flex",justifyContent:"space-evenly"}}>
    <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
    <Button onClick={handleDeleteConfirmed} variant="contained" color="error">Delete</Button>
  </DialogActions>
</Dialog>
      </Paper>
      )}
    </>
  );
}
