import logo from './logo.svg';
import './App.css';
import Login from './login/login';
import SignInForm from './login/login';
import { useState } from 'react';
import SignUpForm from './login/signup';
import MainLogin from './login/main-login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Table from './dashboard/table';
import StickyHeadTable from './dashboard/table';
import ResponsiveDrawer from './dashboard/drawer';
import Edit from './dashboard/edit';
import StickyHeadTable2 from './dashboard/all-clinics';
import ResponsiveDrawer2 from './dashboard/drawer2';
import SubmitedEmails from './dashboard/submited-emails';
import ResponsiveDrawer3 from './dashboard/drawer3';
import ResponsiveDrawer4 from './dashboard/drawer4';
import Register from './login/add -user';


function App() {
 
  return (
   <>
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<MainLogin/>}/>
      <Route path='/dashboard' element={<ResponsiveDrawer/>}/> 
     <Route path='/add-user' element={<Register/>}/>

      <Route path='/edit' element={<Edit/>}/>
      <Route path='/dashboard/accepted' element={<ResponsiveDrawer2/>}/>
      <Route path='/email-submissions' element={<ResponsiveDrawer3/>}/>
      <Route path='/users' element={<ResponsiveDrawer4/>} />
    </Routes>
    </BrowserRouter>
   </>
  );
}

export default App;
