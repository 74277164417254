import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { deleteUser } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB34fjPm-YKmmhuX0StHwBxIncEYPF-FB4",
  authDomain: "waytosmile-e42cf.firebaseapp.com",
  projectId: "waytosmile-e42cf",
  storageBucket: "waytosmile-e42cf.appspot.com",
  messagingSenderId: "1095132832139",
  appId: "1:1095132832139:web:0d62029db95f59d8d0cf8e",
  measurementId: "G-F2SWWV90VV",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);
const auth = getAuth(app);

export { firestore, analytics, auth, app };
