import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { firestore } from "../firebase";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import { Delete, Edit } from "@mui/icons-material";

const columns = [
  { id: "name", label: "Name", minWidth: 170, align: "center" },
  {
    id: "practiceName",
    label: "Practice Name",
    minWidth: 100,
    align: "center",
  },

  { id: "action", label: "Action", minWidth: 170, align: "center" },
];

export default function StickyHeadTable2() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDocId, setDeleteDocId] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(
          query(collection(firestore, "accept"))
        );
        const fetchedData = [];

        querySnapshot.forEach((doc) => {
          const clinicData = doc.data();
          console.log("clinicData", clinicData);
          fetchedData.push({
            name: clinicData.name,
            practiceName: clinicData.practiceName,
            email: clinicData.email,
            website: clinicData.website,
            id: doc.id,
            city: clinicData.city,
            country: clinicData.country,
            postcode: clinicData.postcode,
            contactno: clinicData.contactno,
            instagram: clinicData.instagram,
            receptcha: clinicData.receptcha,
            adress: clinicData.adress,
            adress2: clinicData.adress2,
          });
        });

        setData(fetchedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = data.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDeleteClick = (docId) => {
    setDeleteDocId(docId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteDoc(doc(collection(firestore, "accept"), deleteDocId));
      const updatedData = data.filter((item) => item.id !== deleteDocId);
      setData(updatedData);
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  const handleEditClick = (rowData) => {
    setEditedData(rowData);
    setEditDialogOpen(true);
  };

  const handleEditSave = async () => {
    try {
      // Check if any field in editedData is undefined
      if (Object.values(editedData).some((value) => value === undefined)) {
        throw new Error("Some fields are empty");
      }

      await updateDoc(
        doc(collection(firestore, "accept"), editedData.id),
        editedData
      );
      setEditDialogOpen(false);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const handleEditCancel = () => {
    setEditDialogOpen(false);
  };

  const handleEditFieldChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      {loading ? ( // Render custom loader if data is loading
        <div className="loader">
          <div className="loader__balls">
            <div className="loader__balls__group">
              <div className="ball item1"></div>
              <div className="ball item1"></div>
              <div className="ball item1"></div>
            </div>
            <div className="loader__balls__group">
              <div className="ball item2"></div>
              <div className="ball item2"></div>
              <div className="ball item2"></div>
            </div>
            <div className="loader__balls__group">
              <div className="ball item3"></div>
              <div className="ball item3"></div>
              <div className="ball item3"></div>
            </div>
          </div>
        </div>
      ) : (
        <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "30px" }}>
          <input
            className="search-bar"
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            style={{
              float: "right",
              marginRight: "20px",
              marginTop: "10px",
              width: "40%",
            }}
          />
          <TableContainer sx={{ maxHeight: "55vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: "bolder",
                        fontSize: "18px",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ fontWeight: "bold" }}
                          >
                            {column.id === "website" ? (
                              <a
                                href={value}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {value}
                              </a>
                            ) : column.id === "action" ? (
                              <div>
                                <IconButton
                                  onClick={() => handleEditClick(row)}
                                  style={{ backgroundColor: "green" }}
                                >
                                  <Edit style={{ color: "white" }} />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleDeleteClick(row.id)}
                                  style={{
                                    backgroundColor: "red",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Delete style={{ color: "white" }} />
                                </IconButton>
                              </div>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
            <DialogTitle style={{ paddingTop: "20px" }}>
              Confirm Delete
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                style={{ paddingLeft: "40px", paddingRight: "40px" }}
              >
                Are you sure you want to delete this item?
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                padding: "20px",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button onClick={handleDeleteCancel}>Cancel</Button>
              <Button
                onClick={handleDeleteConfirm}
                variant="contained"
                color="error"
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={editDialogOpen} onClose={handleEditCancel}>
            <DialogTitle>Edit Details</DialogTitle>
            <DialogContent>
              <TextField
                name="name"
                label="Name"
                value={editedData.name || ""}
                onChange={handleEditFieldChange}
                fullWidth
                InputLabelProps={{ shrink: !!editedData.name }}
                sx={{ marginBottom: 3, marginTop: 2 }}
              />
              <TextField
                name="practiceName"
                label="Practice Name"
                value={editedData.practiceName || ""}
                onChange={handleEditFieldChange}
                fullWidth
                InputLabelProps={{ shrink: !!editedData.practiceName }}
                sx={{ marginBottom: 3 }}
              />
              <TextField
                name="adress"
                label="Address1"
                value={editedData.adress || ""}
                onChange={handleEditFieldChange}
                fullWidth
                InputLabelProps={{ shrink: !!editedData.adress }}
                sx={{ marginBottom: 3 }}
              />
              <TextField
                name="adress2"
                label="Address 2"
                value={editedData.adress2 || ""}
                onChange={handleEditFieldChange}
                fullWidth
                InputLabelProps={{ shrink: !!editedData.adress2 }}
                sx={{ marginBottom: 3 }}
              />
              <TextField
                name="city"
                label="City"
                value={editedData.city || ""}
                onChange={handleEditFieldChange}
                fullWidth
                InputLabelProps={{ shrink: !!editedData.city }}
                sx={{ marginBottom: 3 }}
              />
              <TextField
                name="postcode"
                label="Postcode"
                value={editedData.postcode || ""}
                onChange={handleEditFieldChange}
                fullWidth
                InputLabelProps={{ shrink: !!editedData.postcode }}
                sx={{ marginBottom: 3 }}
              />
              <TextField
                name="contactno"
                label="Contact Number"
                value={editedData.contactno || ""}
                onChange={handleEditFieldChange}
                fullWidth
                InputLabelProps={{ shrink: !!editedData.contactno }}
                sx={{ marginBottom: 3 }}
              />
              <TextField
                name="email"
                label="Email"
                value={editedData.email || ""}
                onChange={handleEditFieldChange}
                fullWidth
                InputLabelProps={{ shrink: !!editedData.email }}
                sx={{ marginBottom: 3 }}
              />
              <TextField
                name="website"
                label="Website"
                value={editedData.website || ""}
                onChange={handleEditFieldChange}
                fullWidth
                InputLabelProps={{ shrink: !!editedData.website }}
                sx={{ marginBottom: 3 }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditCancel}>Cancel</Button>
              <Button
                onClick={handleEditSave}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      )}
    </>
  );
}
